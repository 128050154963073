











import { View } from 'client-website-ts-library/plugins';
import { FormField } from 'client-website-ts-library/types/Forms';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class BuyerEnquiry extends Mixins(View) {
  handleFieldInput(data: FormField) {
    const field = document.getElementById(`field_${data.FieldId}`);

    if (field) {
      if (field instanceof HTMLInputElement) {
        const value = field.value;
      } else if (field instanceof HTMLSelectElement) {
        if (field.value === 'Own') {
          // Get all elements with the class "hidden"
          const fieldsHidden = document.getElementsByClassName('hidden');

          // Use forEach to remove the "hidden" class from each element
          Array.from(fieldsHidden).forEach((hiddenField) => {
            hiddenField.classList.remove('hidden');
            hiddenField.classList.add('show');
          });
        } else if (field.value === 'Rent') {
          // Get all elements with the class "hidden"
          const fieldsHidden = document.getElementsByClassName('show');

          // Use forEach to remove the "hidden" class from each element
          Array.from(fieldsHidden).forEach((hiddenField) => {
            hiddenField.classList.remove('show');
            hiddenField.classList.add('hidden');
          });
        }
      } else {
        console.log('Field type not supported.');
      }
    }
  }
}
